import React from "react"

export default function Copyright() {
  return (
    <p>
      {`Created with <3 - `}
      <a href="https://github.com/IVIosi">Me on Github</a>
    </p>
  )
}
